import "./editor.scss"
import "../css/style.scss"

var debug = true
const mobileBreakpoint = 782
let mobileView = false

let adminBarHeight = 0
let siteHeaderHeight = 0

let orientationLandscape = true

const colors = ["puder", "hellblau", "hellgruen"]

jQuery(function ($) {
  $("html").addClass("js") // js is working
  // // get random color
  // let color = localStorage.getItem("color")
  // if (!color) color = colors[0]
  // else {
  //   let nextColor
  //   do {
  //     nextColor = colors[Math.floor(Math.random() * colors.length)]
  //   } while (color === nextColor)
  //   color = nextColor
  // }
  // localStorage.setItem("color", color)
  $("body").addClass(colors[1])

  // for ios touch -> enable active state for links
  document.addEventListener("touchstart", function () {}, false)

  // -----------------------------------------------
  const siteContainer = $(".site-container")

  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu__menu")
  const menuLogo = $(".site-menu__logo")

  const siteTitleLink = $(".site-header__link")
  const menuNavLinks = $(".menu-main > li.menu-item > a")

  // -----------------------------------------------------------------------------------------------------

  setLandscape()
  touchSupport()

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  headerHeight()
  checkMobileView()
  siteHeaderColor()

  // hide and fade in nav and content elements with visibility hidden
  siteContainer.hide()
  siteContainer.css("visibility", "visible")
  siteContainer.fadeIn()

  setTimeout(() => {
    // hide the logo in the menu and show the nav menu
    menuLogo.fadeOut(2000, () => {
      // navContainer.fadeIn(1000)
    })
  }, 1000)

  setTimeout(() => {
    // hide the logo in the menu and show the nav menu
    navContainer.fadeIn(500)
  }, 2000)

  setTimeout(() => {
    // safety
    headerHeight()
  }, 100)

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    const ref = $(this).attr("href")
    if (ref.startsWith("#")) {
      e.preventDefault()

      const $target = $(`${ref}`)

      if ($target.length) {
        const targetMarginTop = $target.css("margin-top").replace("px", "")
        // console.log(targetMarginTop)
        $("html, body").animate(
          {
            scrollTop: $target.offset().top - adminBarHeight - siteHeaderHeight - targetMarginTop
          },
          400
        )
      }
    }
  })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      headerHeight()
      checkMobileView()
      headerHeight()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function headerHeight() {
    siteHeaderHeight = siteHeader.outerHeight()
    $(":root").css("--siteHeaderHeight", `${siteHeaderHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  document.addEventListener("scroll", function (e) {
    siteHeaderColor()
  })

  function siteHeaderColor() {
    if (window.scrollY <= 1) {
      // on top
      siteHeader.removeClass("black")
    } else {
      siteHeader.addClass("black")
    }
  }
})
